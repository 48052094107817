import styled from "styled-components";
import asset2 from "../assets/Asset 2.png";
import Button from "../components/button/Button";
import img from "../assets/10100.png";

import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Img = styled.img`
  width: 100%;
`;
const Img2 = styled.img`
  max-width: 100%;
  border: 1px solid #a9a9a9;
`;
const InnerDash = styled.div`
  width: 100%;
  padding: 0px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const Box = styled.div`
  position: relative;
  flex: 1;
  margin: 5px;

  text-align: center;
  border: 1px solid #c6c6c6;
  min-width: 290px;
  min-height: 245px;
  max-width: 290px;
  max-height: 245px;
`;
const Dashboard = () => {
  const [products, setProducts] = useState(() => {
    fetch(`https://pscuapi.herokuapp.com/api/products`)
      .then((response) => response.json())
      .then((json) => {
        setProducts(json.allproducts);
      });
  });
  console.log(products);
  return (
    <>
      <Header>
        <Img src={asset2} alt="Dashboard" />
      </Header>
      <InnerDash>
        {products?.map((item, index) => {
          return (
            <Box>
              <Link key={index} to={`/inventory/item/${item.id}`}>
                <Img2 src={item.img} alt="" />
                <br />
                {item.stockid}
                <br /> {item.name}
                <br />
                <br />
                <Button>Order Items</Button>
              </Link>
            </Box>
          );
        })}
      </InnerDash>
      <Footer />
    </>
  );
};

export default Dashboard;
