import React, { useState, useContext } from "react";
import styled from "styled-components";
import Header from "./Header";
import Nav from "./Nav";
import Content from "./Content";
import Cart from "./Cart";
import UserContext from "./context/UserContext";
import { useNavigate } from "react-router-dom";

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #f5f5f5;
`;

const ContentWrap = styled.div`
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--nav-height));
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid red;
`;
const NewCart = styled(Cart)`
  position: absolute;
  top: 0;
  right: 0;
`;
//const Navwrap = styled.div``;

const Home = ({ setLogged }) => {
  const navigate = useNavigate();
  const [logbox, setLogbox] = useState(false);
  const { user } = useContext(UserContext);

  return (
    <Wrap>
      <Header logbox={logbox} setLogbox={setLogbox} />

      <Nav logbox={logbox} setLogbox={setLogbox} />

      <Content logbox={logbox} setLogbox={setLogbox}></Content>
    </Wrap>
  );
};

export default Home;
