import React from "react";
import styled from "styled-components";
const Footerwrap = styled.div`
  width: 100%;
  height: 7vh;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;

  background-color: #606060;
`;
const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return <Footerwrap>Copyright &copy; {year} PLS Print</Footerwrap>;
};

export default Footer;
