import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useState, useContext, useEffect, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import LL from "../assets/10100.png";
import Buttons from "../components/button/Button";
import CartContext from "../context/CartContext";
import UserContext from "../context/UserContext";

import { v4 as uuidv4 } from "uuid";
const timestamp = require("time-stamp");
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const InnerWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  position: relative;

  color: var(--primary-color);
`;
const Left = styled.div`
  flex: 1;
  position: relative;

  max-width: 50%;
`;
const Newimg = styled.img`
  max-height: 440px;
  border: 1px solid #a9a9a9;
  z-index: 100;
  width: 100%;
`;
const Right = styled.div`
  flex: 1;
  text-align: left;
  position: relative;
`;
const Head = styled.div`
  width: 100%;
  font-size: 2rem;
  text-align: center;
`;
const Innerleft = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const Info = styled.div`
  width: 100%;
  padding: 20px 40px;
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  li {
    margin: 5px 0px;
  }
`;
const Buuton = styled.div`
  position: relative;
  padding-left: 30px;

  display: flex;
  align-items: center;
  input[type="number"] {
    width: 120px;
    height: 45px;
    font-size: 1.4rem;
    padding-left: 5px;
    margin-right: 30px;
  }
`;
const NewFiArrowUp = styled.div`
  position: absolute;
  top: 2px;
  left: 133px;
  background-color: #c8c8c8;
  color: var(--pimary-color);
`;
const NewFiArrowDown = styled.div`
  position: absolute;
  bottom: 2px;
  left: 133px;
  background-color: #c8c8c8;
  color: var(--pimary-color);
`;
const Headd = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0px;
`;
const HeadInfo = styled.div`
  width: 100%;
  display: flex;
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  &:last-child {
    border-bottom: 1px solid var(--primary-color);
  }
  &:nth-child(even) {
    background-color: lightgrey;
  }

  &:hover {
    background-color: rgb(1, 55, 87, 0.4);
    cursor: pointer;
  }
`;
const Headth = styled.div`
  flex: ${(props) => props.flex};
  font-size: 1.2rem;
  background-color: var(--primary-color);
  padding: 7px 0px;
  color: white;
`;
const Headtd = styled.div`
  flex: ${(props) => props.flex};
  padding: 6px 0px;
`;
const Added = styled.div`
  width: 200px;
  background-color: #2e7d32;
  padding: 8px 16px;
  text-align: center;
  border-radius: 4px;
  color: white;
`;
const Item = () => {
  const foc = useRef();
  const { items, addCart, itemadded, setItemadded } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, handleSubmit } = useForm();
  const [left, setLeft] = useState(0);
  const [product, setProduct] = useState(() => {
    fetch(`https://pscuapi.herokuapp.com/api/products/${id}`)
      .then((response) => response.json())
      .then((json) => {
        setProduct(json.allproducts);
        setLeft(json.allproducts.instock);
      });
  });
  const [orderItems, setOrderItems] = useState(() => {
    fetch(`https://pscuapi.herokuapp.com/api/orders/${id}`)
      .then((response) => response.json())
      .then((json) => {
        setOrderItems(json.allproducts);
      });
  });

  useEffect(() => {
    if (itemadded) {
      const timeId = setTimeout(() => {
        setItemadded(false);
        navigate("/inventory");
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [itemadded]);

  if (!product) {
    return <p>loading...</p>;
  }

  const updateQuantity = (e) => {
    const ql = parseInt(product.instock) - parseInt(e.target.value);
    setLeft(ql);
    // console.log(e.target.value);
    // const exist = items.find((x) => x.id === id);
    // setItems(
    //  items.map((x) => (x.id === id ? { ...exist, quantity: newquantity } : x))
    //);
  };

  return (
    <Container>
      <Headd>
        <Buttons>
          <Link to="/inventory">Return To Inventory </Link>
        </Buttons>
      </Headd>
      <InnerWrap>
        <Left>
          <Innerleft>
            <Newimg src={`../../${product?.img}`} alt="" />
          </Innerleft>
        </Left>
        <Right>
          <form
            onSubmit={handleSubmit(async (data) => {
              addCart(
                data.stockname,
                data.instock,
                data.stockid,
                data.price,
                data.taxable,
                data.size,
                data.type,
                data.colors,
                data.perpack,
                data.mustorder,
                data.cat,
                data.who,
                data.count,
                timestamp("MM/DD/YYYY"),
                uuidv4(),
                data.dbid,
                data.left
              );

              // navigate("/inventory");
            })}
          >
            <Head>{product?.name}</Head>

            <Info>
              Item # {product?.stockid}
              <ul>
                <li>{product?.size}</li>
                <li>{product?.type}</li>
                <li>{product?.colors}</li>
                <li>{product?.perpack} Per Package</li>
                <li style={{ color: "#D32F2F" }}>
                  Must be ordered in increments of {product?.mustorder}
                </li>
                <li>Quantity in stock: {left}</li>
              </ul>
            </Info>

            <input
              type="hidden"
              defaultValue={product?.stockid}
              {...register("stockid")}
            />
            <input
              type="hidden"
              defaultValue={user.fullname}
              {...register("who")}
            />
            <input type="hidden" defaultValue={left} {...register("left")} />
            <input
              type="hidden"
              defaultValue={product?._id}
              {...register("dbid")}
            />
            <input
              type="hidden"
              defaultValue={product?.instock}
              {...register("instock")}
            />
            <input
              type="hidden"
              defaultValue={product?.name}
              {...register("stockname")}
            />
            <input
              type="hidden"
              defaultValue={product?.size}
              {...register("size")}
            />
            <input
              type="hidden"
              defaultValue={product?.type}
              {...register("type")}
            />
            <input
              type="hidden"
              defaultValue={product?.colors}
              {...register("colors")}
            />
            <input
              type="hidden"
              defaultValue={product?.perpack}
              {...register("perpack")}
            />
            <input
              type="hidden"
              defaultValue={product?.mustorder}
              {...register("mustorder")}
            />

            <Buuton>
              <input
                type="number"
                defaultValue="0"
                {...register("count")}
                step={product.mustorder}
                min={product.mustorder}
                max={product.instock}
                onChange={updateQuantity}
              />

              {!itemadded && (
                <Buttons
                  other={
                    product.instock < product.mustorder ? "outofstock" : ""
                  }
                  disabled={product.instock < product.mustorder ? "true" : ""}
                >
                  Order
                </Buttons>
              )}
            </Buuton>
            <br />
            {itemadded && (
              <p>
                <Added>Item Added</Added>
                Redirecting....
              </p>
            )}
          </form>
        </Right>
      </InnerWrap>

      <div
        style={{
          width: "100%",
          height: "30vh",
          marginTop: "30px",
        }}
      >
        <div style={{ width: "100%", fontSize: "1.5rem", padding: "10px 0px" }}>
          Item History
        </div>
        <div style={{ width: "100%", fontSize: "1.2rem" }}>
          <HeadInfo>
            <Headth flex="1">Date Ordered</Headth>
            <Headth flex="1">Quantity</Headth>
            <Headth flex="1">Ordered By</Headth>
            <Headth flex="1">Balance After Order</Headth>
          </HeadInfo>
          {orderItems?.map((orderitem, index) => {
            return (
              <HeadInfo
                key={index}
                flex="1.5"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Headtd key={orderitem.stockid} flex="1">
                  {orderitem.when}
                </Headtd>

                <Headtd flex="1">{orderitem.quantity}</Headtd>
                <Headtd flex="1">{orderitem.who}</Headtd>
                <Headtd flex="1">{orderitem.quantityleft}</Headtd>
              </HeadInfo>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default Item;
