import styled from "styled-components";
import { useEffect, useState } from "react";
import { useContext } from "react";
import UserContext from "../context/UserContext";
import CartContext from "../context/CartContext";
import axios from "axios";
const HeadInfo = styled.div`
  width: 100%;
  display: flex;
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  &:last-child {
    border-bottom: 1px solid var(--primary-color);
  }
  &:nth-child(even) {
    background-color: lightgrey;
  }

  &:hover {
    background-color: rgb(1, 55, 87, 0.4);
    cursor: pointer;
  }
`;
const Headth = styled.div`
  flex: ${(props) => props.flex};
  font-size: 1.2rem;
  background-color: var(--primary-color);
  padding: 7px 0px;
  color: white;
`;
const Headtd = styled.div`
  flex: ${(props) => props.flex};
  padding: 6px 0px;
`;
const Select = styled.select`
  width: 90%;
  font-size: 1.2rem;
`;
const Orders = () => {
  const [orderItems, setOrderItems] = useState([]);
  const [set, setSet] = useState(false);

  useEffect(() => {
    const getOrders = async () => {
      fetch("https://pscuapi.herokuapp.com/api/orders")
        .then((response) => response.json())
        .then((json) => {
          setOrderItems(json.allorders);
        });
    };
    getOrders();
  }, [set]);

  const updateStatus = async (e, id) => {
    await axios
      .patch(`https://pscuapi.herokuapp.com/api/orders/patch/status`, {
        status: e.target.value,
        id,
      })
      .then((response) => {
        // setContacts([...contacts, response.data.contact]);
        //setUser({});
        // setReset(!reset);
        //setEdit(false);
      })
      .catch((error) => {
        // setError(error.response.data.message || "Som thig Wong");
      });
    setSet(!set);
  };

  const { user } = useContext(UserContext);

  return (
    <div
      style={{
        width: "100%",
        height: "30vh",
        marginTop: "30px",
      }}
    >
      <div style={{ width: "100%", fontSize: "1.5rem", padding: "10px 0px" }}>
        Item History
      </div>
      <div style={{ width: "100%", fontSize: "1.2rem" }}>
        <HeadInfo>
          <Headth flex="1">Order #</Headth>
          <Headth flex="1">Item</Headth>
          <Headth flex="1">Quantity</Headth>
          <Headth flex="1">Ordered By</Headth>
          <Headth flex="1">Date Ordered</Headth>
          <Headth flex="1">Balance After Order</Headth>
          <Headth flex="1">Status</Headth>
        </HeadInfo>
        {orderItems?.map((orderitem, index) => {
          return (
            <HeadInfo
              key={index}
              flex="1.5"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Headtd flex="1">{orderitem.orderid}</Headtd>
              <Headtd flex="1">{orderitem.stockid}</Headtd>
              <Headtd flex="1">{orderitem.quantity}</Headtd>
              <Headtd flex="1">{orderitem.who}</Headtd>
              <Headtd key={orderitem.stockid} flex="1">
                {orderitem.when}
              </Headtd>
              <Headtd flex="1">{orderitem.quantityleft}</Headtd>
              {user.type === 1 ? (
                <Headtd flex="1">
                  <Select
                    defaultValue={orderitem.status}
                    onChange={(e) => {
                      updateStatus(e, orderitem.orderid);
                    }}
                  >
                    <option value="Processing">Processing</option>
                    <option value="Shipped">Shipped</option>
                  </Select>
                </Headtd>
              ) : (
                <Headtd flex="1">{orderitem.status}</Headtd>
              )}
            </HeadInfo>
          );
        })}
      </div>
    </div>
  );
};

export default Orders;
