import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useState } from "react";
import axios from "axios";
import Errormodal from "../components/modal/Errormodal";

const InputText = styled.input`
  font-size: 1.1rem;
  width: 90%;
`;
const InputMail = styled.input`
  font-size: 1.1rem;
  width: 90%;
`;
const InputTel = styled.input`
font-size: 1.1rem;
width: 90%;
pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
`;
const ExampleTel = styled.div`
  width: 100%;
  font-size: 0.8rem;
  padding: 5px 0px;
`;
const Pflex = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;
const Cflex = styled.div`
  flex: ${(props) => props.flex || 1};
`;
const NewButton = styled.button`
  position: absolute;
  bottom: 15px;
  left: 30px;
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background: rgb(1, 55, 87, 0.4);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  transition: all 0.5s ease;

  &:focus {
    outline: none;
  }

  &:hover,
  &:active {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
`;
const InputSelect = styled.select`
  font-size: 1.1rem;
  width: 90%;
`;

const NewSystemUser = ({ reset, setReset, setModal }) => {
  const [error, setError] = useState("");

  const { register, handleSubmit } = useForm();

  const getNewList = async () => {
    fetch("https://pscuapi.herokuapp.com/api/users/system")
      //fetch("https://pscuapi.herokuapp.com/api/customer")
      .then((response) => response.json())
      .then((json) => {
        setReset(!reset);
      });
  };

  return (
    <>
      {error > "" && <Errormodal error={error} onClear={() => setError("")} />}
      <form
        onSubmit={handleSubmit(async (data) => {
          if (data.password !== data.confirmpassword) {
            setError("Passwords do not match");
          } else {
            await axios
              .post("https://pscuapi.herokuapp.com/api/users", {
                username: data.username,
                password: data.password,
                status: data.status,
                type: data.type,
                fullname: data.fullname,
                title: data.title,
                auth: data.auth,
              })
              .then((response) => {
                // window.location.href = `/customer`;
                // setContacts([...contacts, response.data.contact]);
                getNewList();
                setModal(false);
              })
              .catch((error) => {
                setError(error.response.data.message || "Som thik Wong");
              });
          }
        })}
      >
        <div style={{ padding: "15px 0px", fontSize: "1.2rem" }}>
          This is for new SYSTEM users only!
        </div>
        <Pflex>
          <Cflex flex="1">
            User Full Name:
            <br />
            <InputText
              type="text"
              {...register("fullname", { required: true })}
            />
          </Cflex>
          <Cflex>
            Username:
            <br />
            <InputText
              type="text"
              {...register("username", { required: true })}
            />
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex flex="1">
            Title:
            <br />
            <InputText type="text" {...register("title")} />
          </Cflex>
          <Cflex flex="1">
            Password:
            <br />
            <InputText
              type="text"
              {...register("password", { required: true })}
            />
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex style={{ flex: "1" }}>
            Status:
            <br />
            <InputSelect
              style={{ width: "90%" }}
              {...register("status", { required: true })}
            >
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </InputSelect>
          </Cflex>
          <Cflex style={{ flex: "1" }}>
            Type:
            <br />
            <InputSelect
              style={{ width: "90%" }}
              {...register("type", { required: true })}
            >
              <option value="1">System User</option>
            </InputSelect>
          </Cflex>
          <Cflex flex="2">
            Confirm Password:
            <br />
            <InputText
              type="text"
              {...register("confirmpassword", { required: true })}
            />
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex style={{ flex: "1.5" }}>
            Auth:
            <br />
            <InputSelect
              style={{ width: "90%" }}
              {...register("auth", { required: true })}
            >
              <option value="1">Account Management</option>

              <option value="6">Admin</option>
            </InputSelect>
          </Cflex>
          <Cflex style={{ flex: "3" }}></Cflex>
          <NewButton type="submit">Submit</NewButton>
        </Pflex>
      </form>
    </>
  );
};

export default NewSystemUser;
