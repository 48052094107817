import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CartContext from "./context/CartContext";
import Button from "./components/button/Button";
import UserContext from "./context/UserContext";
import { Link } from "react-router-dom";
import LL from "./assets/10100.png";
import { RiDeleteBin5Line } from "react-icons/ri";

//const Container = styled.div``;

const Ccontainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Headd = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0px;
`;
const Innerwrap = styled.div`
  width: 100%;
  font-size: 1rem;
`;
const Who = styled.div`
  width: 100%;
  font-size: 1.4rem;
  padding-bottom: 15px;
`;

const Container = styled.div`
  width: 100%;
  height: 80%;
  position: relative;
  overflow-y: auto;
`;
const Footer = styled.div``;
const InnerWrap2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 100%;
  position: relative;
  padding: 10px 0px;
  color: var(--primary-color);
  border-bottom: 1px solid grey;
`;
const Left = styled.div`
  flex: 1;
`;
const Newimg = styled.img`
  max-height: 220px;
  height: 80%;
`;
const Right = styled.div`
  position: relative;
  flex: 1;
  text-align: left;
`;
const Head = styled.div`
  width: 100%;
  font-size: 1rem;
  text-align: left;
`;
const Innerleft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Info = styled.div`
  width: 100%;
  padding: 10px 20px;
  font-size: 0.8rem;
  letter-spacing: 0rem;
  li {
    margin: 2.5px 0px;
  }
`;
const Buuton = styled.div`
  position: absolute;
  padding: 0px 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  input[type="number"] {
    width: 100px;
    height: 35px;
    font-size: 1.4rem;
    padding-left: 5px;
    margin-right: 30px;
  }
`;
const NewFiArrowUp = styled.div`
  position: absolute;
  top: 2px;
  left: 133px;
  background-color: #c8c8c8;
  color: var(--pimary-color);
`;
const NewFiArrowDown = styled.div`
  position: absolute;
  bottom: 2px;
  left: 133px;
  background-color: #c8c8c8;
  color: var(--pimary-color);
`;
const Delete = styled(RiDeleteBin5Line)`
  width: 20px;
  height: 20px;
  float: right;
  &:hover {
    color: red;
    cursor: pointer;
  }
`;
const Added = styled.div`
  width: 100%;
  margin-top: 50px;
  background-color: #2e7d32;
  padding: 8px 16px;
  text-align: center;
  border-radius: 4px;
  color: white;
`;
const Cart = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { items, delCart, updateQuantity, addOrder, itemadded, setItemadded } =
    useContext(CartContext);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setItemadded(false);
      navigate("/");
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }, [itemadded]);
  return (
    <Ccontainer>
      <Headd>
        <Button>
          <Link to="/inventory">Return To Inventory </Link>
        </Button>
      </Headd>

      <Innerwrap>
        <Who>
          {items?.length} Current Items Ordered By: {user?.name}
          <br />
          {itemadded && <Added>Your Order Has Been Placed. Thank You</Added>}
        </Who>

        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addOrder();
            }}
          >
            {items?.map((item) => {
              return (
                <>
                  <InnerWrap2 key={item.id}>
                    <Left>
                      <Innerleft>
                        <Newimg src={item.img} alt="" />
                      </Innerleft>
                    </Left>
                    <Right>
                      <Head>{item?.name}</Head>
                      <Info>
                        Item # {item?.stockid}
                        <ul>
                          {item.size !== "" && <li>{item.size}</li>}
                          {item.type !== "" && <li>{item.type}</li>}
                          {item.colors !== "" && <li>{item.colors}</li>}
                          {item.perpack !== "" && (
                            <li>{item.perpack} Per Package</li>
                          )}
                          {item.mustorder !== "" && (
                            <li>Quantity in stock: {item?.instock || 0}</li>
                          )}
                        </ul>
                      </Info>

                      <input type="hidden" defaultValue={item?.stockid} />

                      <input type="hidden" defaultValue={item?.size} />
                      <Buuton>
                        <input
                          type="number"
                          defaultValue={item.quantity}
                          step={item.mustorder}
                          min={item.mustorder}
                          disabled
                        />

                        <Delete onClick={() => delCart(item.id)} />
                      </Buuton>
                    </Right>
                  </InnerWrap2>
                </>
              );
            })}
            {items.length > 0 && (
              <Button styles={{ marginTop: "20px" }}>Place Order</Button>
            )}
          </form>
        </Container>
      </Innerwrap>
    </Ccontainer>
  );
};

export default Cart;
