import "./spin.css";
import Backdrop from "../backdrop/Backdrop";
const Spin = (props) => {
  return (
    <>
      <Backdrop />
      <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
        <div className="lds-dual-ring"></div>
      </div>
    </>
  );
};

export default Spin;
