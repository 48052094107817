import { createGlobalStyle } from "styled-components";
import Setup from "./api/ROOT";

const GlobalStyles = createGlobalStyle`
:root{
	--nav-height:5vh;
	--header-height:11.5vh;
--primary-color:${Setup.primaryColor};
--linkColor:${Setup.linkColor}
}
body{
	font-family: Arial, Helvetica, sans-serif;
	overflow-x:hidden;
}
*{
	margin:0;
	padding:0;
	box-sizing:border-box;
	
}
.pflex{
	position:relative;
	width:100%;
	display:flex;
}
.cflex{
	flex:1;
}
.calign{
	display:flex;
	justify-content:center;
	align-items:center;
}

.column{
	flex-direction:column;
}
.icons{
	width:30px;
	height:30px;
	margin-right:30px;
	color:var(--primary-color);
}

a:visited, a:active,a:link,a{
color:inherit;
	text-decoration:none;
	display:block;
	
}

.outofstock{
	background-color:rgb(255,0,0,.3);
	color:black;
	&:after {
		content: ' Not Available. Out Of Stock';
		
	  }
	  &:hover{
		background-color:red;
	  }
	 
}
`;
export default GlobalStyles;
