import { useContext, useState } from "react";
import styled from "styled-components";
import NavLinks from "./components/Links";
import CartContext from "./context/CartContext";
import UserContext from "./context/UserContext";
const Container = styled.div`
  position: relative;
  width: 100%;
  height: var(--nav-height));
  background-color: var(--primary-color);
  color: var(--linkColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:5px 0px;
  padding-left:40px;

`;
const Linkwrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logbox = styled.div`
  width: 120px;
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 200;
`;
const Logitem = styled.div`
  width: 100%;
  display: block;
  background-color: var(--primary-color);
  text-align: center;
  padding: 8px 0px;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid var(--primary-color);
  color: white;
  margin-bottom: 5px;
  &:hover {
    background-color: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
`;
const Lwrap = styled.div`
  background-color: var(--primary-color);
`;
const logout = styled.div``;
//const Headerwrap = styled.div``;
const Nav = ({ logbox, setLogbox }) => {
  const { user, logout } = useContext(UserContext);
  const { items } = useContext(CartContext);

  let username = user.fullname.split(" ");
  username = username[0];
  return (
    <Container>
      <Linkwrap onMouseLeave={() => setLogbox(false)}>
        <NavLinks to="/dashboard" text="Home" />
        {user.type === 1 && <NavLinks to="/products" text="Products" />}
        <NavLinks to="/inventory" text="Inventory" />
        <NavLinks to="/orders" text="Order History" />
        {/*<NavLinks to="/cart" text={`Current (${items.length})`} />*/}
        <NavLinks to="/users" text="Users" />
      </Linkwrap>
      <Linkwrap
        onMouseEnter={() => setLogbox(true)}
        style={{ paddingRight: "40px" }}
      >
        Hello {username}
      </Linkwrap>

      {logbox && (
        <Logbox onMouseLeave={() => setLogbox(false)}>
          <Logitem
            style={{
              pointerEvents: "none",
              backgroundColor: "transparent",
              border: "none",
              padding: "6px 0px",
            }}
          ></Logitem>
          <Lwrap>
            <Logitem>Profile</Logitem>
            <Logitem
              onClick={() => {
                {
                  logout();
                }
              }}
            >
              Logout
            </Logitem>
          </Lwrap>
        </Logbox>
      )}
    </Container>
  );
};

export default Nav;
