import styled from "styled-components";
import { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import { Cats } from "../api/Cats";
import Button from "../components/button/Button";
import Modal from "../components/modal/Modal";
import Select from "../components/Select";
import NewProduct from "./NewProduct";
import Spin from "../components/spinner/Spin";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from "axios";
import EditProduct from "./EditProduct";

import UserContext from "../context/UserContext";
import CartContext from "../context/CartContext";
import logo from "../assets/Asset 5.png";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const Count = styled.div`
  width: 100%;
  font-weight: bold;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
`;
const HeadInfo = styled.div`
  width: 100%;
  display: flex;
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  &:last-child {
    border-bottom: 1px solid var(--primary-color);
  }
  &:nth-child(even) {
    background-color: lightgrey;
  }

  &:hover {
    background-color: rgb(1, 55, 87, 0.4);
    cursor: pointer;
  }
`;
const Headth = styled.div`
  flex: ${(props) => props.flex};
  font-size: 1.2rem;
  background-color: var(--primary-color);
  padding: 7px 0px;
  color: white;
`;
const Headtd = styled.div`
  flex: ${(props) => props.flex};
  padding: 6px 0px;
`;
const Taxable = styled.select`
  width: 90%;
  font-size: 0.9rem;
`;

const Edit = styled(TiEdit)`
  width: 20px;
  height: 20px;
  &:hover {
    color: green;
    cursor: pointer;
  }
`;
const Delete = styled(RiDeleteBin5Line)`
  width: 20px;
  height: 20px;
  &:hover {
    color: red;
    cursor: pointer;
  }
`;
const IMG = styled.img`
  height: 50px;
  border: 1px solid #a9a9a9;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(2.5);
    z-index: 200;
  }
`;
const Inventory = () => {
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [products, setProducts] = useState([]);
  const [resett, setResett] = useState(false);
  const [loading, setLoading] = useState(true);
  const getproducts = async () => {
    setLoading(true);
    await fetch(`https://pscuapi.herokuapp.com/api/products`)
      .then((response) => response.json())
      .then((json) => {
        setProducts(json.allproducts);
      });
    setLoading(false);
  };
  useEffect(() => {
    getproducts();
  }, []);
  useEffect(() => {
    getproducts();
    setResett(false);
  }, [resett]);
  const handleDelete = (id) => {
    axios
      .delete(`https://pscuapi.herokuapp.com/api/products/delete/${id}`)
      .then(setResett(true));
  };
  const { user } = useContext(UserContext);
  const { items } = useContext(CartContext);

  return (
    <>
      {
        <Modal
          show={edit}
          header="Edit Current Product"
          footer={<Button onClick={(e) => setEdit(null)}>Cancel</Button>}
        >
          <EditProduct
            cats={Cats}
            setModal={setEdit}
            setResett={setResett}
            id={edit}
          />
        </Modal>
      }
      {loading && <Spin asOverlay />}
      <Container>
        <img src={logo} alt="" style={{ width: "100%" }} />
        <Count>
          Current Products: {products.length}{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {items.length > 0 && `Current Cart: ${items.length}`}
        </Count>

        <HeadInfo>
          <Headth flex="2"></Headth>
          <Headth flex="1">Item ##</Headth>
          <Headth flex="3">Name</Headth>
          <Headth flex="1">Category</Headth>
          <Headth flex=".8">Reorder</Headth>
          <Headth flex="1">In Stock</Headth>
        </HeadInfo>
        {products.map((item, index) => {
          return (
            <Link key={index} to={`/inventory/item/${item.id}`}>
              <HeadInfo>
                <Headtd flex="2" style={{ textAlign: "center" }}>
                  <IMG src={item.img} alt="" />
                </Headtd>
                <Headtd key={item.stockid} flex="1">
                  {item.stockid}
                </Headtd>
                <Headtd flex="3">{item.name}</Headtd>
                <Headtd flex="1">{item.cat}</Headtd>
                <Headtd flex=".8">{item.reorderlvl}</Headtd>
                <Headtd
                  flex="1"
                  style={{
                    color: item.instock <= item.reorderlvl ? "red" : "inherit",
                  }}
                >
                  {item.instock}
                </Headtd>
              </HeadInfo>
            </Link>
          );
        })}
      </Container>
    </>
  );
};

export default Inventory;
