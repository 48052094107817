import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useState } from "react";
import axios from "axios";

const InputText = styled.input`
  text-transform: capitalize;
  font-size: 1.1rem;
  width: 90%;
`;
const InputMail = styled.input`
  font-size: 1.1rem;
  width: 90%;
`;
const InputTel = styled.input`
font-size: 1.1rem;
width: 90%;
pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
`;
const ExampleTel = styled.div`
  width: 100%;
  font-size: 0.8rem;
  padding: 5px 0px;
`;
const Pflex = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;
const Cflex = styled.div`
  flex: ${(props) => props.flex || 1};
`;
const NewButton = styled.button`
  position: absolute;
  bottom: 15px;
  left: 30px;
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background: rgb(1, 55, 87, 0.4);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  transition: all 0.5s ease;

  &:focus {
    outline: none;
  }

  &:hover,
  &:active {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
`;
const InputSelect = styled.select`
  font-size: 1.1rem;
  width: 90%;
`;
const EditProduct = ({ cats, setModal, setResett, id }) => {
  const { register, handleSubmit } = useForm();
  const [product, setProduct] = useState(() => {
    fetch(`https://pscuapi.herokuapp.com/api/products/${id}`)
      .then((response) => response.json())
      .then((json) => {
        setProduct(json.allproducts);
      });
  });

  if (!product) {
    return <p>loading...</p>;
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          await axios
            .patch(`https://pscuapi.herokuapp.com/api/products/patch/`, {
              id: data.id,
              name: data.name,
              reorderlvl: data.reorderlvl,
              instock: data.instock,
              stockid: data.stockid,
              price: data.price,
              taxable: data.taxable,
              cat: data.category,
              size: data.size,
              type: data.type,
              colors: data.colors,
              perpack: data.perpack,
              mustorder: data.mustorder,
            })
            .then((response) => {
              // window.location.href = `/customer`;
              // setContacts([...contacts, response.data.contact]);
              setResett(true);
              setModal(null);
            })
            .catch((error) => {
              //setError(error.response.data.message || "Som thik Wong");
            });
        })}
      >
        <input
          type="hidden"
          value={id}
          {...register("id", { required: true })}
        />
        <Pflex>
          <Cflex flex="3">
            Product Name:
            <br />
            <InputText
              type="text"
              defaultValue={product.name}
              {...register("name", { required: true })}
            />
          </Cflex>
          <Cflex>
            Item Number:
            <br />
            <InputText
              defaultValue={product.stockid}
              type="text"
              {...register("stockid", { required: true })}
            />
          </Cflex>
          <Cflex style={{ flex: "2" }}>
            Category
            <br />
            <InputSelect
              defaultValue={product.cat}
              style={{ width: "90%" }}
              {...register("category", { required: true })}
            >
              {cats.map((catitem, index) => {
                return (
                  <option key={index} value={catitem}>
                    {catitem}
                  </option>
                );
              })}
            </InputSelect>
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex style={{ flex: "1" }}>
            Reorder At:
            <br />
            <InputText
              defaultValue={product.reorderlvl}
              type="text"
              {...register("reorderlvl", { required: true })}
            />
          </Cflex>
          <Cflex style={{ flex: "1" }}>
            In Stock:
            <br />
            <InputText
              defaultValue={product.instock}
              type="text"
              {...register("instock")}
            />
          </Cflex>
          <Cflex style={{ flex: "1" }}>
            Paper Type:
            <br />
            <InputText
              defaultValue={product.type}
              type="text"
              {...register("type")}
            />
          </Cflex>
          <Cflex style={{ flex: "1" }}>
            Size:
            <br />
            <InputText
              defaultValue={product.size}
              type="text"
              {...register("size")}
            />
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex style={{ flex: "1" }}>
            Colors:
            <br />
            <InputText
              defaultValue={product.colors}
              type="text"
              {...register("colors")}
            />
          </Cflex>
          <Cflex style={{ flex: "1" }}>
            Per Package:
            <br />
            <InputText
              defaultValue={product.perpack}
              type="text"
              {...register("perpack")}
            />
          </Cflex>
          <Cflex style={{ flex: "1" }}>
            Must Order In:
            <br />
            <InputText
              defaultValue={product.mustorder}
              type="text"
              {...register("mustorder")}
            />
          </Cflex>
          <Cflex>
            Price:
            <br />
            <InputTel
              defaultValue={product.price}
              type="text"
              defaultValue="$0.00"
              {...register("price", { required: true })}
            />
          </Cflex>
          <Cflex>
            Taxable:
            <br />
            <InputSelect
              style={{ width: "90%" }}
              {...register("taxable", { required: true })}
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </InputSelect>
          </Cflex>
          <NewButton type="submit">Submit</NewButton>
        </Pflex>
      </form>
    </>
  );
};

export default EditProduct;
