import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import DATA from "../api/DATA";
import { Cats } from "../api/Cats";
import Button from "../components/button/Button";
import Modal from "../components/modal/Modal";
import NewProduct from "./NewProduct";
import Spin from "../components/spinner/Spin";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import axios from "axios";
import EditProduct from "./EditProduct";
import LL from "../assets/10100.png";
import logo from "../assets/Asset 6.png";
import UserContext from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1300px;
`;
const Count = styled.div`
  width: 100%;
  font-weight: bold;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
`;
const HeadInfo = styled.div`
  width: 100%;
  display: flex;
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);

  &:last-child {
    border-bottom: 1px solid var(--primary-color);
  }
  &:nth-child(even) {
    background-color: lightgrey;
  }

  &:hover {
    background-color: rgb(1, 55, 87, 0.4);
    cursor: pointer;
  }
`;
const Headth = styled.div`
  flex: ${(props) => props.flex};
  font-size: 1.2rem;
  background-color: var(--primary-color);
  padding: 7px 0px;
  color: white;
`;
const Headtd = styled.div`
  flex: ${(props) => props.flex};
  padding: 6px 0px;
`;
const Taxable = styled.select`
  width: 90%;
  font-size: 0.9rem;
`;

const Edit = styled(TiEdit)`
  width: 20px;
  height: 20px;
  &:hover {
    color: green;
    cursor: pointer;
  }
`;
const Delete = styled(RiDeleteBin5Line)`
  width: 20px;
  height: 20px;
  &:hover {
    color: red;
    cursor: pointer;
  }
`;
const IMG = styled.img`
  height: 50px;
  border: 1px solid #a9a9a9;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(2.5);
    z-index: 200;
  }
`;
const Products = () => {
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [products, setProducts] = useState([]);
  const [resett, setResett] = useState(false);
  const [loading, setLoading] = useState(true);
  const getproducts = async () => {
    setLoading(true);
    await fetch(`https://pscuapi.herokuapp.com/api/products`)
      .then((response) => response.json())
      .then((json) => {
        setProducts(json.allproducts);
      });
    setLoading(false);
  };

  useEffect(() => {
    getproducts();
    setResett(false);
    console.log("rendered");
  }, [resett]);
  const handleDelete = (id) => {
    axios
      .delete(`https://pscuapi.herokuapp.com/api/products/delete/${id}`)
      .then(setResett(true));
  };
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  if (user.type !== 1) {
    navigate("/");
  }

  return (
    <>
      {
        <Modal
          show={modal}
          header="Add New Product"
          footer={<Button onClick={(e) => setModal(!modal)}>Cancel</Button>}
        >
          <NewProduct cats={Cats} setModal={setModal} setResett={setResett} />
        </Modal>
      }
      {
        <Modal
          show={edit}
          header="Edit Current Product"
          footer={<Button onClick={(e) => setEdit(null)}>Cancel</Button>}
        >
          <EditProduct
            cats={Cats}
            setModal={setEdit}
            setResett={setResett}
            id={edit}
          />
        </Modal>
      }
      {loading && <Spin asOverlay />}
      <Container>
        <img src={logo} alt="" style={{ width: "100%" }} />
        <Count>
          Current Products: {products.length}
          <Button
            type="button"
            className="button"
            onClick={() => setModal(true)}
          >
            Add Product
          </Button>
        </Count>
        <HeadInfo>
          <Headth flex="2"></Headth>
          <Headth flex="1">Item #</Headth>
          <Headth flex="3">Name</Headth>
          <Headth flex=".8">Reorder</Headth>
          <Headth flex=".5">Price</Headth>
          <Headth flex=".5">Tax</Headth>
          <Headth flex="1">Category</Headth>
          <Headth flex=".4"></Headth>
          <Headth flex=".4"></Headth>
        </HeadInfo>
        {products.map((item, index) => {
          return (
            <HeadInfo
              key={index}
              flex="1.5"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Headtd flex="2" style={{ textAlign: "center" }}>
                <IMG src={item.img} alt="" />
              </Headtd>
              <Headtd key={item.stockid} flex="1">
                {item.stockid}
              </Headtd>

              <Headtd flex="3">{item.name}</Headtd>
              <Headtd flex=".8">{item.reorderlvl}</Headtd>
              <Headtd flex=".5">{item.price}</Headtd>

              <Headtd flex=".5">
                {item.taxable === 0 ? <p>No</p> : <p>Yes</p>}
              </Headtd>
              <Headtd flex="1">{item.cat}</Headtd>
              <Headtd flex=".4">
                <Edit
                  onClick={() => {
                    setEdit(item._id);
                  }}
                />
              </Headtd>
              <Headtd flex=".4">
                <Delete
                  onClick={() => {
                    handleDelete(item._id);
                  }}
                />
              </Headtd>
            </HeadInfo>
          );
        })}
      </Container>
    </>
  );
};

export default Products;
