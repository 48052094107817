import React, { createContext, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

export function UserProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    loggedin: false,
  });

  const setuser = (info) => {
    setUser({
      name: info.username,
      loggedin: true,
      fullname: info.fullname,
      title: info.title,
      status: info.status,
      type: info.type,
      auth: info.auth,
      id: info._id,
    });
  };
  const logout = () => {
    navigate("/");
    setUser({
      name: "",
      loggedin: false,
    });
  };
  return (
    <UserContext.Provider value={{ user, setuser, logout }}>
      {children}
    </UserContext.Provider>
  );
}
export default UserContext;
