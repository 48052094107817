import "./links.css";
import { NavLink } from "react-router-dom";

const Links = ({ to, text }) => {
  return (
    <NavLink to={to} className="link">
      {text}
    </NavLink>
  );
};

export default Links;
