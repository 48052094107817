import Modal from "./Modal";
import Button from "../button/Button";

const ErrorModal = (props) => {
  return (
    <Modal
      style={{ zIndex: "800", minHeight: "50%" }}
      onCancel={props.onClear}
      header="An Error Occurred!"
      show={!!props.error}
      footer={<Button onClick={props.onClear}>Okay</Button>}
    >
      <p>{props.error}</p>
    </Modal>
  );
};

export default ErrorModal;
