import Logo from "../assets/Asset 7.png";

//import Input from "../Components/Input";
import Button from "../components/button/Button";
import "./Login.css";
import { useState, useContext, useRef } from "react";
import Spin from "../components/spinner/Spin";
import UserContext from "../context/UserContext";
import { useForm } from "react-hook-form";
import ErrorModal from "../components/modal/Errormodal";
import styled from "styled-components";
import axios from "axios";
import emailjs from "@emailjs/browser";

/*const Loginwrapper = styled.div`

`;*/
const Loginwrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 200px;
`;
const Logoo = styled.img`
  max-height: 61px;
  margin: 25px 0px;
`;
const Loginwrap = styled.div`
  width: 100%;
  height: 240px;
  background-color: var(--primary-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  position: relative;
`;
const Box = styled.input`
  width: 100%;
  font-size: 1.3rem;
  margin-bottom: 15px;
  position: relative;
`;
const Boxlabel = styled.label`
  width: 100%;
  font-size: 1.3rem;
  position: relative;
  color: var(--primary-color);
`;
const Login = () => {
  const { register, handleSubmit } = useForm();
  const { user } = useContext(UserContext);
  const { setuser } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {isLoading && <ErrorModal show={error} header="Log In Failed" />}
      <Loginwrapper>
        <form
          onSubmit={handleSubmit(async (data) => {
            setIsLoading(true);
            await axios
              .post("https://pscuapi.herokuapp.com/api/users/login", {
                username: data.username,
                password: data.password,
                from_name: "Me",
                message: "New Login",
              })
              .then((response) => {
                // window.location.href = `/customer`;

                console.log(response);
                setIsLoading(false);
                setuser(response.data.userinfo);
              })
              .catch((error) => {
                setIsLoading(false);
                setError(true);
              });
          })}
        >
          {isLoading && <Spin asOverlay />}
          <Loginwrap>
            <Logoo src={Logo} alt="Highground Logo" />
            <Boxlabel>Username:</Boxlabel>
            <Box {...register("username", { required: true })} />
            <Boxlabel>Password:</Boxlabel>
            <Box
              type="password"
              {...register("password", { required: true })}
            />
            <div className="loginforget">
              <div>Forgot Password</div>
            </div>
            <div className="subarea">
              <Button type="submit">Log In</Button>
            </div>

            <div className="copyight">
              HighGround Software. All Rights Reserved.
            </div>
          </Loginwrap>
        </form>
      </Loginwrapper>
    </>
  );
};
export default Login;
