import { useContext } from "react";
import styled from "styled-components";
import assest3 from "./assets/Asset 3.png";
import assest4 from "./assets/Asset 4.png";
import assest7 from "./assets/Asset 7.png";

import { CgProfile } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BsClipboardCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import CartContext from "./context/CartContext";
import UserContext from "./context/UserContext";
const Logowrap = styled.div`
  width: 30%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
`;
const Logo = styled.img`
  height: 85%;
`;

const Headerwrap = styled.div`
  position: relative;
  padding: 10px 0px;
  width: 100%;
  height: var(--header-height);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid var(--primary-color);
  z-index: 10;
`;
const IconsWrap = styled.div`
position relative;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items:  flex-end;
`;
const CartSpan = styled.div`
  position: absolute;
  right: 45%;
  top: -20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #d32f2f;
`;
const Icon = styled.img`
  margin-right: 40px;
  height: 28px;
`;

const Header = ({ logbox, setLogbox }) => {
  const { items } = useContext(CartContext);
  const { user } = useContext(UserContext);

  return (
    <Headerwrap onMouseEnter={() => setLogbox(false)}>
      <Logowrap>
        <Logo src={assest7} alt="PSCU Logo" />
      </Logowrap>

      <IconsWrap>
        <Icon src={assest3} alt="support" style={{ height: "37px" }} />
        <Link to="/cart" style={{ position: "relative" }}>
          <Icon src={assest4} alt="cart" />
          <CartSpan>{items.length}</CartSpan>
        </Link>
      </IconsWrap>
    </Headerwrap>
  );
};

export default Header;
