import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useState, useContext } from "react";
import axios from "axios";
import Errormodal from "../components/modal/Errormodal";
import { useParams } from "react-router-dom";
import UserContext from "../context/UserContext";

const InputText = styled.input`
  font-size: 1.1rem;
  width: 90%;
`;
const InputMail = styled.input`
  font-size: 1.1rem;
  width: 90%;
`;
const InputTel = styled.input`
font-size: 1.1rem;
width: 90%;
pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
`;
const ExampleTel = styled.div`
  width: 100%;
  font-size: 0.8rem;
  padding: 5px 0px;
`;
const Pflex = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;
const Cflex = styled.div`
  flex: ${(props) => props.flex || 1};
`;
const NewButton = styled.button`
  position: absolute;
  bottom: 15px;
  left: 30px;
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  background: rgb(1, 55, 87, 0.4);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  transition: all 0.5s ease;

  &:focus {
    outline: none;
  }

  &:hover,
  &:active {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
`;
const InputSelect = styled.select`
  font-size: 1.1rem;
  width: 90%;
`;

const EditUser = ({ id, setEdit, reset, setReset }) => {
  const { user } = useContext(UserContext);

  const [error, setError] = useState("");
  const [newpass, setNewpass] = useState(true);
  const [useri, setUser] = useState(() => {
    fetch(`https://pscuapi.herokuapp.com/api/users/user/${id}`)
      //fetch("https://pscuapi.herokuapp.com/api/customer")
      .then((response) => response.json())
      .then((json) => {
        setUser(json.userinfo);
      });
  });

  const handleChange = (e) => {
    setUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handlePassword = () => {
    setNewpass(!newpass);
  };

  const { register, handleSubmit } = useForm();

  if (!useri) {
    return <p>Loading...</p>;
  }
  return (
    <>
      {error > "" && <Errormodal error={error} onClear={() => setError("")} />}
      <form
        onSubmit={handleSubmit(async (data) => {
          if (data.newpassword !== data.confirmnewpassword) {
            setError("Passwords do not match");
          } else {
            await axios
              .patch(`https://pscuapi.herokuapp.com/api/users/patch/${id}`, {
                username: data.username,
                password: data.password,
                status: data.status,
                type: data.type,
                fullname: data.fullname,
                title: data.title,
                auth: data.auth,
                newpassword: data.newpassword,
              })
              .then((response) => {
                // window.location.href = `/customer`;
                // setContacts([...contacts, response.data.contact]);
                setUser({});
                setReset(!reset);
                setEdit(false);
              })
              .catch((error) => {
                setError(error.response.data.message || "Som thig Wong");
              });
          }
        })}
      >
        <input type="hidden" {...register("password")} value={useri.password} />
        <Pflex>
          <Cflex flex="1">
            User Full Name:
            <br />
            <InputText
              value={useri?.fullname}
              type="text"
              {...register("fullname", { required: false })}
              onChange={handleChange}
            />
          </Cflex>
          <Cflex flex="1">
            User Full Name:
            <br />
            <InputText
              value={useri?.username}
              type="text"
              {...register("username", { required: false })}
              onChange={handleChange}
            />
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex flex="1">
            Title:
            <br />
            <InputText
              type="text"
              {...register("title")}
              defaultValue={useri?.title}
            />
          </Cflex>
          <Cflex flex="1">
            Password:
            <br />
            <InputText
              type="password"
              {...register("newpassword", { required: !newpass })}
              disabled={newpass}
            />
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex style={{ flex: "1" }}>
            Status:
            <br />
            <InputSelect
              defaultValue={useri?.status}
              style={{ width: "90%" }}
              {...register("status", { required: true })}
            >
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </InputSelect>
          </Cflex>
          <Cflex style={{ flex: "1" }}>
            Type:
            <br />
            <InputSelect
              defaultValue={useri?.type}
              style={{ width: "90%" }}
              {...register("type", { required: true })}
            >
              <option value="0">User</option>
              {user.auth === 6 && <option value="1">System User</option>}
            </InputSelect>
          </Cflex>
          <Cflex flex="2">
            Confirm Password:
            <br />
            <InputText
              type="password"
              {...register("confirmnewpassword", { required: !newpass })}
              disabled={newpass}
            />
          </Cflex>
        </Pflex>
        <Pflex>
          <Cflex style={{ flex: "1.5" }}>
            Auth:
            <br />
            <InputSelect
              defaultValue={useri?.auth}
              style={{ width: "90%" }}
              {...register("auth", { required: true })}
            >
              <option value="0">User</option>
              {user?.auth === 6 && useri.type === 1 && (
                <option value="6">Admin</option>
              )}
              {user?.auth !== 0 && (
                <option value="1">Account Management</option>
              )}
            </InputSelect>
          </Cflex>
          <Cflex style={{ flex: "3" }}></Cflex>
          <Cflex style={{ flex: "2" }}>
            <input type="checkbox" onChange={handlePassword} /> Change Password
          </Cflex>
          <NewButton type="submit">Update</NewButton>
        </Pflex>
      </form>
    </>
  );
};

export default EditUser;
