import styled from "styled-components";
import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Products from "./pages/Products";
import Dashboard from "./pages/Dashboard";
import Inventory from "./pages/Inventory";
import Orders from "./pages/Orders";
import Users from "./pages/Users";
import Item from "./pages/Item";
import Cart from "./Cart";
import UserContext from "./context/UserContext";
import CartContext from "./context/CartContext";

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1350px;
  margin: 0px auto;
  height: calc(100vh - var(--header-height) - var(--nav-height));
  min-height: calc(
    100vh - var(--header-height) - var(--nav-height) - var(--nav-height)
  );
  background-color: #e8e8e8;
  z-index: 100;
  overflow-y: auto;
  padding: 0px 20px;
`;

const Content = ({ logbox, setLogbox }) => {
  const { logout } = useContext(UserContext);
  const { itemwasadded } = useContext(CartContext);
  return (
    <>
      {itemwasadded && <p>item added</p>}
      <Container onMouseEnter={() => setLogbox(false)}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/products" element={<Products />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/users" element={<Users />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/inventory/item/:id" element={<Item />} />
        </Routes>
      </Container>
    </>
  );
};

export default Content;
