import styled from "styled-components";
import { useState, useContext } from "react";
import Home from "./Home";
import Login from "./pages/Login";
import { Routes, Route } from "react-router-dom";
import UserContext from "./context/UserContext";
import { CartProvider } from "./context/CartContext";
import Test from "./test";
const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
`;

const App = () => {
  const { user } = useContext(UserContext);
  return !user.loggedin ? (
    <Wrap className={`align`}>
      <Login />
      <Test />
    </Wrap>
  ) : (
    <Wrap>
      <CartProvider>
        <Home />
      </CartProvider>
    </Wrap>
  );
};

export default App;
