import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import Button from "../../components/button/Button";
import Modal from "../../components/modal/Modal";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import NewSystemUser from "../../pages/NewSystemUser";
import axios from "axios";
import UserContext from "../../context/UserContext";
import EditUser from "../../pages/EditUser";
const Container = styled.div`
  width: 100%;
`;
const Count = styled.div`
  width: 100%;
  font-weight: bold;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
`;
const HeadInfo = styled.div`
  width: 100%;
  display: flex;
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);

  &:last-child {
    border-bottom: 1px solid var(--primary-color);
  }
  &:nth-child(even) {
    background-color: lightgrey;
  }

  &:hover {
    background-color: rgb(1, 55, 87, 0.4);
    cursor: pointer;
  }
`;
const HeadInfo2 = styled.div`
  width: 100%;
  display: flex;
  border-left: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);

  &:last-child {
    border-bottom: 1px solid var(--primary-color);
  }
  &:nth-child(even) {
    background-color: lightgrey;
  }

  &:hover {
    background-color: rgb(1, 55, 87, 0.4);
    cursor: pointer;
  }
`;
const Headth = styled.div`
  flex: ${(props) => props.flex};
  font-size: 1.2rem;
  background-color: var(--primary-color);
  padding: 7px 0px;
  color: white;
`;
const Headtd = styled.div`
  flex: ${(props) => props.flex};
  padding: 6px 0px;
`;
const Taxable = styled.select`
  width: 90%;
  font-size: 0.9rem;
`;
const Edit = styled(TiEdit)`
  width: 20px;
  height: 20px;
  &:hover {
    color: green;
    cursor: pointer;
  }
`;
const Delete = styled(RiDeleteBin5Line)`
  width: 20px;
  height: 20px;
  &:hover {
    color: red;
    cursor: pointer;
  }
`;

const Userlist = ({
  list,
  handleDelete,
  setModal,
  setModal2,
  text,
  auth,
  page,
  setReset,
  reset,
}) => {
  const switchNumberToAuth = (num) => {
    switch (num) {
      case 6:
        text = "Admin";
        break;
      case 1:
        text = "Acct Manager";
        break;
      case 0:
        text = "User";
    }
    return text;
  };
  const [edit, setEdit] = useState(false);
  const [current, setCurrent] = useState("");
  const switchNumberToStatus = (num) => {
    switch (num) {
      case 1:
        text = "Active";
        break;
      case 0:
        text = "Non-Active";
    }
    return text;
  };
  const { user } = useContext(UserContext);
  return (
    <>
      {
        <Modal
          show={edit}
          header="Edit User"
          footer={<Button onClick={(e) => setEdit(false)}>Cancel</Button>}
        >
          <EditUser
            id={current}
            setEdit={setEdit}
            set=""
            setReset={setReset}
            reset={reset}
          />
        </Modal>
      }
      <Container>
        <Count>
          Current System Users: {list?.length}
          {(page === "system" && auth === 6) ||
          (page === "user" && auth === 6) ||
          (page === "user" && auth === 1) ? (
            <Button
              type="button"
              className="button"
              onClick={() => setModal(true)}
            >
              {text}
            </Button>
          ) : null}
        </Count>
        <HeadInfo>
          <Headth flex="1">Name</Headth>
          <Headth flex="1">Username</Headth>
          <Headth flex="1">Title</Headth>
          <Headth flex=".5">Status</Headth>
          <Headth flex=".8">Auth</Headth>
          <Headth flex=".5"></Headth>
          <Headth flex=".5"></Headth>
        </HeadInfo>
        {list?.map((item) => {
          return (
            <>
              <HeadInfo
                key={item.id}
                flex="1.5"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Headtd flex="1" style={{ paddingLeft: "5px" }}>
                  {item.fullname}
                </Headtd>
                <Headtd flex="1">{item.username}</Headtd>
                <Headtd flex="1">{item.title}</Headtd>
                <Headtd flex=".5">{switchNumberToStatus(item.status)}</Headtd>
                <Headtd flex=".8">{switchNumberToAuth(item.auth)}</Headtd>
                <Headtd flex=".5">
                  {user.auth === 6 ||
                  item.id === user.id ||
                  (user.auth === 1 && item.type === 0) ? (
                    <Edit
                      onClick={() => {
                        setCurrent(item._id);
                        setEdit(true);
                      }}
                    />
                  ) : null}
                </Headtd>
                <Headtd flex=".5">
                  {user.auth === 6 || (user.auth === 1 && item.type === 0) ? (
                    <Delete
                      onClick={() => {
                        handleDelete(item.id);
                      }}
                    />
                  ) : null}
                </Headtd>
              </HeadInfo>
            </>
          );
        })}
      </Container>
    </>
  );
};

export default Userlist;
