import React, { createContext, useState } from "react";
import emailjs from "@emailjs/browser";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const CartContext = createContext();

export function CartProvider({ children }) {
  const [itemadded, setItemadded] = useState(false);
  const [items, setItems] = useState([]);

  const [data, setData] = useState([]);

  const addCart = (
    name,
    instock,
    stockid,
    price,
    taxable,
    size,
    type,
    colors,
    perpack,
    mustorder,
    cat,
    who,
    quantity,
    when,
    id,
    dbid
  ) => {
    setItems((prevState) => [
      ...prevState,
      {
        name,
        instock,
        stockid,
        price,
        taxable,
        size,
        type,
        colors,
        perpack,
        mustorder,
        cat,
        who,
        quantity,
        when,
        id,
        dbid,
      },
    ]);
    setItemadded(true);
    let dd =
    '<tr style="font-size: 1.3rem"><td style="width: 40%; padding: 10px 0px">#10104: #9 White Regular Envelope CS:</td><td style="width: 30%; border-bottom: 1px solid black; padding: 10px 0px">500</td><td style="width: 5%; padding: 10px 0px"></td><td style="width: 30%; border-bottom: 1px solid black; padding: 10px 0px"></td><td style="width: 5%; padding: 10px 0px"></td></tr><tr style="width: 100%; padding: 20px"><td colspan="5" style="padding: 20px">&nbsp;</td></tr>';
    setData((prev) => [...prev, dd]);
  };

  const delCart = (id) => {
    const newPeople = items.filter((item) => {
      return item.id !== id;
    });
    setItems(newPeople);
  };

  const updateQuantity = (id, newquantity) => {
    const exist = items.find((x) => x.id === id);
    setItems(
      items.map((x) => (x.id === id ? { ...exist, quantity: newquantity } : x))
    );
  };
  const addOrder = () => {
    const orderid = Math.floor(100000 + Math.random() * 900000);

    items.map((item) => {
      const newstock = item.instock - item.quantity;
      axios
        .post("https://pscuapi.herokuapp.com/api/orders", {
          name: item.name,
          instock: item.instock,
          stockid: item.stockid,
          price: item.price,
          taxable: item.taxable,
          cat: item.category,
          size: item.size,
          type: item.type,
          colors: item.colors,
          perpack: item.perpack,
          mustorder: item.mustorder,
          who: item.who,
          quantity: item.quantity,
          when: item.when,
          id: item.id,
          orderid: orderid,
          dbid: item.dbid,
          quantityleft: newstock,
        })
        .then((response) => {
          setItems([]);
          setData([]);
          setItemadded(true);

          // window.location.href = `/customer`;
          // setContacts([...contacts, response.data.contact]);
          //setModal(false);
          // setResett(true);
        })
        .catch((error) => {
          console.log(error);
          //setError(error.response.data.message || "Som thik Wong");
        });
    });
    let tester =

    '<table style="border-collapse: collapse; width: 100%;height: 18px;margin-left: auto;margin-right: auto;border: 1px solid black;" ><thead style="border-bottom: 2px solid black"><tr><th style="width: 40%; padding: 10px 0px"></th><th style="width: 30%;text-align: left;padding: 10px 0px;font-size: 1.5rem;">Ordered</th><th style="width: 5%"></th><th style="width:30%;text-align:left;padding:10px 0px;font-size:1.5rem;">Pulled</th><th style="width:5%"></th></tr><thead><tbody style="">'+data.join("")+'</tbody></table>';

    let newdata = {
      who: items[0].who,
      orderid,
      tester,
    };
    emailjs.send(
      "service_noym28u",
      "template_ssmiajj",
      newdata,
      "1G46Whd4s6QytMzE_"
    );
  };

  return (
    <CartContext.Provider
      value={{
        items,
        addCart,
        delCart,
        updateQuantity,
        addOrder,
        itemadded,
        setItemadded,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;
