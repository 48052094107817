import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import Button from "../components/button/Button";
import Modal from "../components/modal/Modal";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBin5Line } from "react-icons/ri";
import NewsystemUser from "./NewSystemUser";
import NewUser from "./NewUser";
import axios from "axios";
import UserContext from "../context/UserContext";
import Userlist from "../components/Users/Userlist";

const Users = () => {
  const { user } = useContext(UserContext);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [reset, setReset] = useState(false);
  const [systemUsers, setSystemUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const getSystemUsers = async () => {
    await fetch("https://pscuapi.herokuapp.com/api/users/system")
      //fetch("https://pscuapi.herokuapp.com/api/customer")
      .then((response) => response.json())
      .then((json) => {
        setSystemUsers(json.allsystemusers);
      });
  };

  const getUsers = async () => {
    await fetch("https://pscuapi.herokuapp.com/api/users/all")
      //fetch("https://pscuapi.herokuapp.com/api/customer")
      .then((response) => response.json())
      .then((json) => {
        setAllUsers(json.allusers);
      });
  };
  useEffect(() => {
    getUsers();
    getSystemUsers();
  }, [reset]);

  const handleDelete = (id) => {
    axios
      .delete(`https://pscuapi.herokuapp.com/api/users/delete/${id}`)
      .then(setReset(!reset));
  };

  return (
    <>
      {
        <Modal
          show={modal}
          header="Add New System User"
          footer={<Button onClick={(e) => setModal(false)}>Cancel</Button>}
        >
          <NewsystemUser
            setReset={setReset}
            reset={reset}
            setModal={setModal}
            set=""
          />
        </Modal>
      }
      {
        <Modal
          show={modal2}
          header="Add New System User"
          footer={<Button onClick={(e) => setModal2(false)}>Cancel</Button>}
        >
          <NewUser
            setReset={setReset}
            reset={reset}
            setModal={setModal2}
            set=""
          />
        </Modal>
      }
      {}
      {user.type === 1 && (
        <Userlist
          list={systemUsers}
          handleDelete={handleDelete}
          setModal={setModal}
          text="Add New System User"
          auth={user.auth}
          page="system"
          setReset={setReset}
          reset={reset}
        />
      )}
      <br />
      <br />
      <Userlist
        list={allUsers}
        handleDelete={handleDelete}
        setModal={setModal2}
        text="Add New User"
        auth={user.auth}
        page="user"
        setReset={setReset}
        reset={reset}
      />
    </>
  );
};

export default Users;
